import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import "../Style/Experience.css"
import Arrow from "../images/arrow.svg"

const Experience = props => (
    <div>
    <div className="ExperienceCard">

       <div id="ExperienceWrapperWithoutLink">
           <div className="ExTitleWithoutLink">{props.title}</div>
           <div className="SubText">{props.subtext}</div>
       </div>

       <div className="Role">{props.role}</div>
       <div className="Years">{props.years}</div>

    </div>
</div>
)

export default Experience