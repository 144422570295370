import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import "../Style/Experience.css"
import Arrow from "../images/arrow.svg"

const LinkExperience = props => (
    <div>
    <div className="ExperienceCard">

       <div id="ExperienceWrapper">
           <div style={{ marginTop: `4px`}}><img src={Arrow} width="22" height="17" /></div>
           <div className="ExTitle"><Link to={props.link}>{props.title}</Link></div>
           <div className="SubText">{props.subtext}</div>
       </div>

       <div className="Role">{props.role}</div>
       <div className="Years">{props.years}</div>

    </div>
</div>
)

export default LinkExperience