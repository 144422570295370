import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from 'gatsby-image'
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo.js"

import "../Style/Index.css"
import "../Style/EssayCard.css"
import TitleUnderlined from "../components/TitleUnderlined"
import Experience from "../components/Experience"
import LinkExperience from "../components/LinkExperience"
import Arrow from "../images/arrow.svg"
import ArrowDark from "../images/arrowDark.svg"

import {  ModalRoutingContext } from "gatsby-plugin-modal-routing"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Phil Murphy" />

    <Helmet>

        <link rel="subresource" href="../Style/EssayPage.css" />

        <meta name="twitter:description" content="Hey, it's me — Phil a.k.a. NERFWRLD; I write and ramble on about entertainment here. You can also find some of my work experience. Let's stay in touch!">
        </meta>

        <meta name="og:description" content="Hey, it's me — Phil a.k.a. NERFWRLD; I write and ramble on about entertainment here. You can also find some of my work experience. Let's stay in touch!">
        </meta>

        <meta name="description" content="Hey, it's me — Phil a.k.a. NERFWRLD; I write and ramble on about entertainment here. You can also find some of my work experience. Let's stay in touch!">
        </meta>

        <meta name="image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image:alt" content="Hey, it's me — Phil a.k.a. NERFWRLD; I write and ramble on about entertainment here. You can also find some of my work experience. Let's stay in touch!">
        </meta>

        <meta name="thumbnail" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="og:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

    </Helmet>



    <div className="SizeWrapper">

    <div><MyLogo /></div>

    <div className="IntroBlock">

      <p>Hey, it’s Phil —</p>
      <p>Let’s talk about digital content.</p>

      <p>We’re living in a time in which any feasible type of it is available at our fingertips anytime, anywhere.</p>

      <p>Entertainment, education, sport, gaming, music — all of that became possible because of all the great creators tirelessly creating shows, podcasts, and other kinds of art.</p>

      <p>But it’s much more than just content. For the first time in history, it’s something that unites nations, countries, and races.</p>
        
      <p>We get together with community meetings, peer-to-peer interactions, and gatherings.</p>

      <p>It’s time to celebrate the best sides of digital content, help more people to become creators, empower curation, and ensure we’re addressing important problems and trying to fix them.</p>

    </div>
    
      <TitleUnderlined title="My essays" color="green" />

      <div className="SetEssayCards">

      <Link to="/Community-Seeking"><div className="CardWrapper2021">
            <div className="CW_Title1">Community Seeking</div>
            <div className="CW_Title2">I spent my last 7 years working with famous YouTubers and livestreamers to learn about how their content became an effective remedy for mental health issues.</div>
            <div className="CW_Label_Green">Research</div>
        </div></Link>

        <Link to="/Racism-Sexism-LGBT"><div className="CardWrapper2021">
            <div className="CW_Title1">On Racism, Sexism, and LGBT+</div>
            <div className="CW_Title2">It can be hard to understand what's happening in the world. So I tried to give the most neutral position and explanation.</div>
            <div className="CW_Label_Red">Politics</div>
        </div></Link>
        
        <Link to="/Obvious-Aint-So-Obvious"><div className="CardWrapper2021">
            <div className="CW_Title1">Obvious Ain't So Obvious</div>
            <div className="CW_Title2">It's okay to explain something obvious, because it's not so apparent after all.</div>
            <div className="CW_Label_Purple">Philosophy</div>
        </div></Link>


      </div>



      <div className="underline"></div>

      <TitleUnderlined title="Work experience" color="red" />

        <Experience 
          title="SuperRare" 
          role="Director, Product & Experience/Strategy Lead" 
          years="2020-2022" 
        />

        <LinkExperience 
          title="Criken's Colosseum" 
          link="Crikens-Colosseum"
          role="Producer (Eq. to PM), Lead Designer" 
          years="2019-2020" 
        />

        <LinkExperience 
          title="FrameNinja" 
          link="FrameNinja"
          role="Founder, CEO, Lead Designer" 
          years="2018-2020" 
          subtext="on hold"
        />        
        
        <LinkExperience 
          title="MBN" 
          link="MBN"
          role="Product Designer" 
          years="2018" 
        />

        <Experience 
          title="Independent Strategist & Designer"
          role="Working on creative strategies for artists and creator economy's startups" 
          years="Since Fall 2013" 
        />


      <div className="underline"></div>

      <TitleUnderlined title="A few things about me" color="yellow" />


    <div className="BulletList">
            <p>    — Yo, I’m <b>Phil Murphy</b> a.k.a. <b>NERFWRLD</b></p>
            <p>    — Keen on <b>livestreaming</b>, <b>digital art,</b> and <b>gaming</b></p>
            <p>    — Learned HTML/CSS when I was 7 <i>(thanks to my dad)</i> — began my adventure</p>
            <p>    — Been creating and developing tech + entertainment products since 2013  </p>
            <p>    — Genuinely love working on creative strategies for products and brands. So much fun.</p>
            <p>    — <b>Don't follow any schedules!</b> Meaning always free if needed, unless I'm already into something</p>
            <p>    — Always down to play together! Invite me to <b>Rocket League</b>, <b>R6</b>, <b>CS:GO</b>, <b>Betrayal</b>, <b>PUBG</b> or anything with <b>co-op</b> :)</p>
            <p>    — Huge fan of <b>Mafia II</b>, <b>Halsey</b>, <b>Milka</b> and <b>Big Bang Theory</b> </p>
            <p>    — Currently caught up in <b>game design</b>, <b>human rights</b> and <b>social psychology</b>. // Would like to chat if you have something interesting to share.</p>

            <br />

            <p>    <b>Working on something exciting in tech or entertainment?</b> Please reach out to me — would like to learn more :)</p>

            <br />

            <p className="SubTitle"><span className="TextHighlight">Let's chat 👇</span></p>
            <p className="email">nerf@hey.com</p>
            <p>Or slide into my <a href="https://twitter.com/NERFWRLD" target="_blank" rel="noopener">Twitter DMs</a></p>
     </div>


    </div>


  </Layout>
)

export default IndexPage

export const query = graphql`
  query {

    CommunitySeekingThumbnail: file(relativePath: { eq: "CommunitySeekingThumbnail.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    RSLPicture: file(relativePath: { eq: "RSLPicture.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ObviousObviousPicture: file(relativePath: { eq: "ObviousPicture.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    CommunitySeekingPicture: file(relativePath: { eq: "CommunitySeekingPicture.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }


  }
`